import './countdown.scss';

class Countdown {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-countdown',
            timerAttr: 'timer'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$countdown = $element;
        this.$countdownTimer = this.$countdown.querySelector('[' + this.settings.initAttr + '="' + this.settings.timerAttr + '"]');

        this.$daysWrapper = this.$countdownTimer.querySelector('[' + this.settings.initAttr + '="days"]');
        this.$hoursWrapper = this.$countdownTimer.querySelector('[' + this.settings.initAttr + '="hours"]');
        this.$minutesWrapper = this.$countdownTimer.querySelector('[' + this.settings.initAttr + '="minutes"]');
        this.$secondsWrapper = this.$countdownTimer.querySelector('[' + this.settings.initAttr + '="seconds"]');

        this.countdownEnddate = new Date(this.$countdownTimer.getAttribute('datetime')).getTime();
        this.countdownInterval = null;

        this.initialize();
    }

    initialize () {
        if (this.$countdownTimer && this.countdownEnddate !== '') {
            this.initCountdown();
        }
    }

    initCountdown () {
        // Update function every second
        this.countdownInterval = window.setInterval(() => {
            this.setCountdown();
        }, 1000);
    }

    setCountdown () {
        // Get today's date and time
        const currentDate = new Date().getTime();

        // Get distance between dates
        // console.log(this.countdownEnddate, currentDate);
        let distance = this.countdownEnddate - currentDate;

        if (distance <= 0) {
            clearInterval(this.countdownInterval);
            distance = 0;
        }

        // Calculate result
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Set result
        if (this.$daysWrapper) {
            if (days < 10 && this.$daysWrapper) {
                this.$daysWrapper.innerHTML = '0' + days;
            } else {
                this.$daysWrapper.innerHTML = days;
            }
        }

        if (this.$hoursWrapper) {
            if (hours < 10) {
                this.$hoursWrapper.innerHTML = '0' + hours;
            } else {
                this.$hoursWrapper.innerHTML = hours;
            }
        }

        if (this.$minutesWrapper) {
            if (minutes < 10) {
                this.$minutesWrapper.innerHTML = '0' + minutes;
            } else {
                this.$minutesWrapper.innerHTML = minutes;
            }
        }

        if (this.$secondsWrapper) {
            if (seconds < 10) {
                this.$secondsWrapper.innerHTML = '0' + seconds;
            } else {
                this.$secondsWrapper.innerHTML = seconds;
            }
        }
    }
}

export { Countdown };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$countdown = $context.querySelectorAll('[data-countdown="root"]');
        for (let i = 0; i < $$countdown.length; i++) {
            const $countdown = $$countdown[i];

            const countdownAPI = new Countdown($countdown);
            $countdown.API = countdownAPI;
        }
    }
});
